* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    font-family: 'Roboto', sans-serif;
}

/* React-Toastify */
:root {
    --toastify-toast-width: 350px;
}