.showroom-page-wrapper {
    z-index: 2000;
}

.showroom-page-b2b-button-panel {
    background-color: #c2c2c2;
    margin-bottom: 5px;
    margin-right: 15px;
    padding: 25px 20px;
    border-radius: 20px;
    border-bottom-right-radius: 0;
}