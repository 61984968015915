.images-not-found {
    width: 100px;
    height: 100px;
}

.image-gallery {
    width: 350px;
    height: 380px;
}

.image-gallery-slide img {
    width: 100%;
    height: 300px;
}

.fullscreen .image-gallery-slide img {
    width: 100%;
    height: 85vh;
}

.image-gallery-thumbnail,
.image-gallery-thumbnail-inner,
.image-gallery-thumbnail-image {
    max-width: 65px !important;
    max-height: 65px;
}

.fullscreen .image-gallery-thumbnail,
.fullscreen .image-gallery-thumbnail-inner,
.fullscreen .image-gallery-thumbnail-image {
    max-width: 120px !important;
    max-height: 120px;
}